import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    FunctionField,
    SelectArrayInput
} from 'react-admin';

const ProductionTitle = props => {
    return <span>{props.record ? `${props.record.name}` : ''}</span>;
};

const toTitleCase = (str) => {
    return str
        .replace(/_/g, ' ')
        .replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
};

export const ProductionList = props => (
    <List {...props} resource="productions">
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="company.name" label="Company" />
            <TextField source="name" />
            <TextField source="address" />
            <DateField source="created_at" />
            <TextField source="admins.length" label="Admins" />
            <FunctionField
                label="Timesheet Template"
                render={record => toTitleCase(record?.timesheet_pdf_template || 'Standard')}
            />
            <FunctionField
                label="Disabled Fields"
                render={record => {
                    if (!record.disabled_fields || record.disabled_fields.length === 0) {
                        return 'None';
                    }

                    return record.disabled_fields.map(field =>
                        field.charAt(0).toUpperCase() + field.slice(1)
                    ).join(', ').replaceAll('_', ' ');
                }}
            />
        </Datagrid>
    </List>
);

export const ProductionEdit = props => {
    const transform = (data) => {
        data.company_id = data.company ? data.company.id : undefined;
        delete data.company;
        delete data.admins;
        delete data.timesheet_weeks;
        delete data.departments;
        delete data.penalty_types;
        return data;
    };

    const choices = [
        { id: 'standard', value: 'Standard', },
        { id: 'single_per_diem', value: 'Single Per Diem', },
        { id: 'shooting_per_diem', value: 'Shooting Per Diem', },
    ];

    return (<Edit {...props} title={<ProductionTitle />} undoable={false} transform={transform}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput multiline source="address" />
            <ReferenceInput label="Company" source="company.id" reference="companies" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="timesheet_pdf_template" choices={choices} optionText="value" optionValue="id" />
            <SelectArrayInput
                source="disabled_fields"
                choices={[
                    { id: 'mileage', name: 'Mileage' },
                    { id: 'allowance', name: 'Allowance' },
                    { id: 'consecutive_days', name: 'Consecutive Days' },
                ]}
                multiple={true}
            />
        </SimpleForm>
    </Edit>)
};
