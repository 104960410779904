import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    useRefresh,
    useNotify,
    useRedirect,
    SelectArrayInput
} from 'react-admin';

export const ProductionCreate = props => {
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const transform = (data) => {
        data.company_id = data.company ? data.company.id : undefined;
        delete data.company;
        return data;
    };

    const onSuccess = ({data}) => {
        notify('ra.notification.created', 'info', {smart_count: 1});
        redirect(`/productions/${data.id}/show/admins`);
        refresh();
    }

    const choices = [
        {id: 'standard', value: 'Standard',},
        {id: 'single_per_diem', value: 'Single Per Diem',},
        {id: 'shooting_per_diem', value: 'Shooting Per Diem',},
    ];

    return (<Create {...props} onSuccess={onSuccess} transform={transform}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput multiline source="address"/>
            <ReferenceInput label="Company" source="company.id" reference="companies" allowEmpty>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <SelectInput source="timesheet_pdf_template" choices={choices} optionText="value" optionValue="id"/>
            <SelectArrayInput
                source="disabled_fields"
                choices={[
                    {id: 'mileage', name: 'Mileage'},
                    {id: 'allowance', name: 'Allowance'},
                    {id: 'consecutive_days', name: 'Consecutive Days'},
                ]}
                multiple={true}
            />
        </SimpleForm>
    </Create>)
};
